.react-confirm-alert-body > h1 {
    text-align: center;
}
.react-confirm-alert-body{
    text-align: center;
}
.react-confirm-alert-button-group{
    display: block;
}
.react-confirm-alert-button-group > button{
    position: relative;
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 0.875rem;
    will-change: transform;
    color: #fff;
    background-color: #11cdef;
    border-color: #11cdef;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.modal-confirm {
    color: #636363;
    width: 400px;
  }
  .modal-confirm .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
    text-align: center;
    font-size: 14px;
  }
  .modal-confirm .modal-header {
    border-bottom: none;
    position: relative;
    display: block;
  }
  .modal-footer{
    display: block;
  }
  .modal-confirm h4 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -10px;
  }
  .modal-confirm .close {
    position: absolute;
    top: -5px;
    right: -2px;
  }
  .modal-confirm .modal-body {
    color: #999;
  }
  .modal-confirm .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
    padding: 10px 15px 25px;
  }
  .modal-confirm .modal-footer a {
    color: #999;
  }
  .modal-confirm .icon-box {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 50%;
    z-index: 9;
    text-align: center;
    border: 3px solid green;
  }
  .modal-confirm .icon-box i {
    color: green;
    font-size: 46px;
    display: inline-block;
    margin-top: 13px;
  }
  .modal-confirm .btn {
    color: #fff;
    border-radius: 4px;
    background: #60c7c1;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    min-width: 120px;
    border: none;
    min-height: 40px;
    border-radius: 3px;
    margin: 0 5px;
    outline: none !important;
  }
  .modal-confirm .btn-info {
    background: #c1c1c1;
  }
  .modal-confirm .btn-info:hover, .modal-confirm .btn-info:focus {
    background: #a8a8a8;
  }
  .modal-confirm .btn-danger {
    background: #f15e5e;
  }
  .modal-confirm .btn-danger:hover, .modal-confirm .btn-danger:focus {
    background: #ee3535;
  }
  .trigger-btn {
    display: inline-block;
    margin: 100px auto;
  }
  #busy-holder
{
    background: transparent;
    width: 100%;
    height: 100%;        
}
.loading-btn{
  display: none;
}

.loader{
  /* position: fixed; */
  left: 0px;
  top: 0px;
  width: 100%;
  height:350px;
  z-index: 9999;
  background: url('//upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif') 
              50% 50% no-repeat rgb(249,249,249);
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
