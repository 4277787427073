#Executive,#ProfitLoss,#BalanceSheet{
    background: #FFEFEF;
    border: 1px solid #FFCDCD;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 28px;
color: #666666;
}


#GeneralLedger{
background: #F2FFE7;
border: 1px solid #D2FFAC;
box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
border-radius: 12px;
}
/* #ProfitLoss{
background: #FFEFEF;
border: 1px solid #ffcdcd93;
box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
border-radius: 12px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 28px;
}
#GeneralLedger{
background: #FBF2EF;
border: 1px solid #ffded29d;
box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
border-radius: 12px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 28px;
}
#BalanceSheet{
background: #F2FFE7;
border: 1px solid #d1ffac9c;
box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
border-radius: 12px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 28px;
} */
.card{
    margin-bottom: 25px;
    cursor: pointer;
}
#download-btn{
margin-bottom: 25px;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
color: #FFFFFF;

}
.nav-fill .nav-item {
    flex: unset;
    text-align: center;
}
.bgcolor {
    background: #D6FFFF;
    border: 1px solid #02b6b6;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #666666;
}
/* .bgcolor .card-title{
    color: #fff !important;
} */
/* .nav-wrapper {
    display: none;
} */
.reportnavwrapper{
    display: none;
}